import { Amplify } from 'aws-amplify'
import { Authenticator } from "@aws-amplify/ui-react"
import '@aws-amplify/ui-react/styles.css'
import awsExports from './aws-exports'
import React from 'react'
import './App.css'
import { Settings } from './Settings'
import { Clock } from './Clock.js'
import { DateControl } from './DateControl.js'
import { createNote, updateNote, deleteNote} from './graphql/mutations'
import { listNotes } from './graphql/queries'
import { Button, Text, Flex, Heading, ScrollView, TextAreaField, TextField } from "@aws-amplify/ui-react"
import { useCallback, useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Menu, MenuItem, Icon, View, Divider, SearchField } from '@aws-amplify/ui-react'
import { Expander, ExpanderItem, Collection, Card } from '@aws-amplify/ui-react'
import { downloadObjectsAsJSON, yyyymmddhhmm } from './embers'

import { Space } from './Space'
import { Help } from './Help'
import { Max } from './Max'
import {CLI} from './CLI'
import { Dashboard } from './Dashboard'
import { Explorer } from './Explorer'
import { CreateNote } from './CreateNote'
import { NoteEditor } from './NoteEditor'
import { Note } from './Note'
import userEvent from '@testing-library/user-event'

import { MdOutlinePostAdd } from "react-icons/md"
import { MdOutlineClose } from "react-icons/md"


Amplify.configure(awsExports)

function App({ signOut, user }) {
  let context = {
    version: '0.1',
    user: null
  }

  let state = {
    options: {
      display: {
        mode: ['single-pane', 'double-pane', 'multi-pane']
      }
    },
    display: {
      mode: 'double-pane'
    }
  }

  let settings = {
    minMultiPanelWidth: 1024,
    explorerSortBy: 'updatedAt'
  }

  const [newNoteText, setNewNoteText] = useState('')
  const [editedNoteId, setEditedNoteId] = useState(0)
  const [currentNote, setCurrentNote] = useState(null)
  useEffect(() => {
    localStorage.setItem('newNoteText', JSON.stringify(newNoteText));
    localStorage.setItem('editedNoteId', JSON.stringify(editedNoteId));
    localStorage.setItem('currentNote', JSON.stringify(currentNote));
  }, [newNoteText, editedNoteId, currentNote])
  const [demoMode, setDemoMode] = useState(false)
  const [ notes, setNotes ] = useState([])
  const [ hint, setHint ] = useState('')
  const [ filteredNotes, setFilteredNotes ] = useState([])
  const [ dashboardPanelState, setDashboardPanelState ] = useState(false)
  const [ helpPanelState, setHelpPanelState ] = useState(false)
  const [ maxPanelState, setMaxPanelState ] = useState(false)
  const [ cliPanelState, setCLIPanelState ] = useState(false)
  const [ createNotePanelState, setCreateNotePanelState ] = useState(false)
  const [ editNotePanelState, setEditNotePanelState ] = useState(false)
  const [ viewNotePanelState, setViewNotePanelState ] = useState(false)
  const [ showExplorerPanelState, setShowExplorerPanelState ] = useState(true)
  const [ settingsPanelState, setSettingsPanelState ] = useState(false)
  const [ showNotesFilterPanelState, setShowNotesFilterPanelState ] = useState(false)
  const [ explorerSortBy, setExplorerSortBy ] = useState(settings.explorerSortBy)
  const [ maxCommand, setMaxCommand ] = useState('')
  const [ maxCells, setMaxCells ] = useState([])
  const [ cliCommand, setCliCommand ] = useState('')
  const [ cliCells, setCliCells ] = useState([])

  const handleShowExplorer = useCallback(async () => {
    setEditNotePanelState(false)
    setCreateNotePanelState(false)
    setHelpPanelState(false)
    setMaxPanelState(false)
    setCLIPanelState(false)
    setDashboardPanelState(false)
    setViewNotePanelState(false)
    setShowExplorerPanelState(true)
  }, [])

  const handleHideExplorer = useCallback(async () => {
    setShowExplorerPanelState(false)
  }, [])

  const handleToggleExplorer = useCallback(async () => {
    setShowExplorerPanelState(showExplorerPanelState ? false : true) 
  }, [showExplorerPanelState])

  const handleCreateNote = () => { // TODO: remove: useCallback(async () => {
    setEditNotePanelState(false)
    setHelpPanelState(false)
    setMaxPanelState(false)
    setCLIPanelState(false)
    setDashboardPanelState(false)
    setViewNotePanelState(false)
    if (window.innerWidth < settings.minMultiPanelWidth) {
      setShowExplorerPanelState(false)
    }
    setCurrentNote({
      text: '',
      type: 'note',
      status: 'open',
      tags: '[]',
      context: '[]'
  })
    setCreateNotePanelState(true)
  } //, [])

  const handleEditNote = (note) => {
    setEditedNoteId(note.id)
    setNewNoteText(note.text)
    setCurrentNote(note)
    setCreateNotePanelState(false)
    setDashboardPanelState(false)
    setHelpPanelState(false)
    setMaxPanelState(false)
    setCLIPanelState(false)
    setViewNotePanelState(false)
    if (window.innerWidth < settings.minMultiPanelWidth) {
      setShowExplorerPanelState(false)
    }
    setEditNotePanelState(true)
  }

  const handleViewNote = (note) => {
    setEditedNoteId(note.id)
    setNewNoteText(note.text)
    setCurrentNote(note)
    setCreateNotePanelState(false)
    setDashboardPanelState(false)
    setHelpPanelState(false)
    setMaxPanelState(false)
    setCLIPanelState(false)
    setEditNotePanelState(false)
    if (window.innerWidth < settings.minMultiPanelWidth) {
      setShowExplorerPanelState(false)
    }
    setViewNotePanelState(true)
  }

  const handleShowDashboard = () => {
    setCreateNotePanelState(false)
    setShowExplorerPanelState(false)
    setEditNotePanelState(false)
    setHelpPanelState(false)
    setMaxPanelState(false)
    setCLIPanelState(false)
    setViewNotePanelState(false)
    if (window.innerWidth < settings.minMultiPanelWidth) {
      setShowExplorerPanelState(false)
    }
    setDashboardPanelState(true)
  }

  const handleShowHelp = () => {
    setCreateNotePanelState(false)
    setShowExplorerPanelState(false)
    setEditNotePanelState(false)
    setDashboardPanelState(false)
    setViewNotePanelState(false)
    setMaxPanelState(false)
    setCLIPanelState(false)
    if (window.innerWidth < settings.minMultiPanelWidth) setShowExplorerPanelState(false)
    setHelpPanelState(true)
  }

  const handleShowSettings = () => {
    setCreateNotePanelState(false)
    setShowExplorerPanelState(false)
    setEditNotePanelState(false)
    setDashboardPanelState(false)
    setViewNotePanelState(false)
    setMaxPanelState(false)
    setCLIPanelState(false)
    if (window.innerWidth < settings.minMultiPanelWidth) setShowExplorerPanelState(false)
    setHelpPanelState(false)
    setSettingsPanelState(true)
  }

  const handleShowMax = () => {
    setCreateNotePanelState(false)
    setShowExplorerPanelState(false)
    setEditNotePanelState(false)
    setDashboardPanelState(false)
    setViewNotePanelState(false)
    setHelpPanelState(false)
    setCLIPanelState(false)
    if (window.innerWidth < settings.minMultiPanelWidth) {
      setShowExplorerPanelState(false)
    }
    setMaxPanelState(true)
  }

  const handleShowCLI = () => {
    setCreateNotePanelState(false)
    setShowExplorerPanelState(false)
    setEditNotePanelState(false)
    setDashboardPanelState(false)
    setViewNotePanelState(false)
    setHelpPanelState(false)
    setMaxPanelState(false)
    if (window.innerWidth < settings.minMultiPanelWidth) {
      setShowExplorerPanelState(false)
    }
    setCLIPanelState(true)
  }

  const handleDownloadNotes = () => {
    downloadObjectsAsJSON(notes, "createplando_" + yyyymmddhhmm() + ".json")
  }

  const fetchNotes = useCallback(async () => {
    const result = await API.graphql({
      query: listNotes,
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    })
    if (demoMode) { // TODO
      console.log('Demo mode on')
      result = {
        "data":{
          "listNotes":{
            "items":[
              {"id":"0","text":"You are in demo mode.\nIn demo mode, test data built into the app will be used rather than retrieving data from the database.","type":"Note","status":"Open","tags":"[]","context":"[\"demo\", \"help\"]","createdAt":"2022-08-05T05:46:50.515Z","updatedAt":"2022-08-05T05:46:50.515Z","owner":"mark"},
              {"id":"1","text":"This note has some tags.","type":"Note","status":"Open","tags":"[\"important\",\"reference\"]","context":"[\"demo\",\"test-data\"]","createdAt":"2022-08-05T05:46:50.515Z","updatedAt":"2022-08-05T05:46:50.515Z","owner":"mark"}
            ]
          }
        }
      }
    } else {
    }
    setNotes(result.data.listNotes.items)
    setFilteredNotes(filterNotes(result.data.listNotes.items))
  }, [setNotes, filteredNotes])

  const filterNotes = () => {
    let result = notes.filter(note => note.text.toLowerCase().includes(searchCriteria.toLowerCase()))
    if (explorerSortBy === 'updatedAt') {
      result = result.sort((a, b) => (a.updatedAt > b.updatedAt) ? -1: 1)
    } else if (explorerSortBy === 'createdAt') {
      result = result.sort((a, b) => (a.createdAt > b.createdAt) ? -1: 1)
    }
    return result
  }

  const [searchCriteria, setSearchCriteria] = useState('')
  const ExplorerBar = () => {

    return (
      <Flex direction={"row"}>
        <TextField
        id='search-field'
        key="searchCriteria"
        size='small'
        onChange={ onSearchCriteriaChange }      
        placeholder="Search..."
        />
      </Flex>
    )
  }

  const ToolBarControls = () => {
    let result = null
    if (showExplorerPanelState) {
      result = ExplorerBar()
    }
    return result
  }

  const onSearchCriteriaChange = (e) => {
    const { value } = e.target
    setSearchCriteria(value)
  }

  const Status = () => {
    const states = {
      'grinning': '😀',
      'smiling': '🙂',
      'rolling eyes': '🙄',
      'frowning': '🙁',
      'neutral': '😐',
      'thinking': '🤔',
      'astonished': '😲',
      'sleeping': '😴',
      'worried': '😟',
      'angry': '😠',
      'persevering': '😣',
      'grimacing': '😬',
      'upside-down': '🙃',
      'nerd': '🤓',
      'rofl': '🤣',
      'raised eyebrow': '🤨',
      'zany': '🤪',
      'exploding head': '🤯',
      'halo': '😇'
    }
    const [status, setStatus] = useState(states['smiling'])
    return (
      <Text>{status}</Text>
    )
  }
    
  const handleSaveNote = useCallback(async () => {
    try {
      const newNoteText = JSON.parse(localStorage.getItem('newNoteText'));
      const q = {
        query: createNote,
        variables: { input: currentNote },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }
      await API.graphql(q)
      setCreateNotePanelState(false)
      setShowExplorerPanelState(true)
      fetchNotes()
    } catch(err) {
      console.log("Error saving new note:", err)
    }
  }, [fetchNotes])

  const handleUpdateNote = useCallback(async () => {
    try {
      const editedNoteId = JSON.parse(localStorage.getItem('editedNoteId'));
      const newNoteText = JSON.parse(localStorage.getItem('newNoteText'));
      const {
        // extract these as they cannot be updated
        createdAt,
        updatedAt,
        owner,
        // put the rest in updatedContent to include in the graphql update
        ...updatedNote
    } = currentNote

      const q = {
        query: updateNote,
        variables: { input: updatedNote },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      }
      await API.graphql(q)
      setEditNotePanelState(false)
      setShowExplorerPanelState(true)
      fetchNotes() // TODO: Optimise to not fetch all the notes every time one is edited
    } catch(err) {
      console.log("Error updating note:", err)
    }
  }, [fetchNotes])

  const handleCancelCreateNote = () => {
    setCreateNotePanelState(false)
    setShowExplorerPanelState(true)
  }

  const handleCancelEditNote = () => {
    setEditNotePanelState(false)
    setShowExplorerPanelState(true)
  }

  const handleCloseViewNote = () => {
    setViewNotePanelState(false)
    setShowExplorerPanelState(true)
  }

  const handleDeleteNote = useCallback(async (id) => {
    try {
      await API.graphql({
        query: deleteNote,
        variables: { input: { id: id } },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      fetchNotes()
    } catch(err) {
      console.log("Error deleting note:", err)
    }
  }, [fetchNotes])

  const handleExplorerClose = () => {
    setShowExplorerPanelState(false)
  }

  const handleSettingsClose = () => {
    setSettingsPanelState(false)
  }


  useEffect(() => {
    fetchNotes()
  }, [fetchNotes])

  const AppBody = ({ signOut, user }) => {
    return (
      <Flex id="container" direction={"column"} height={"100vh"} padding={"10px"}>
        <Flex id="status-bar" direction={"row"}>
          <Heading>Create Plan Do</Heading>
          <DateControl/>
          <Clock/>
          <Text>{Object.keys(notes).length} Notes</Text>
          <Status/>
        </Flex>
        { /* Space({name:'Workspace one'}, filteredNotes, handleViewNote, handleEditNote, handleDeleteNote) */}
        <Flex id="menuBar" direction={"row"} alignItems={"center"}>
          <Menu menuAlign="start">
            <MenuItem onClick={() => handleShowDashboard()}>
              Dashboard
            </MenuItem>
            <MenuItem onClick={() => handleToggleExplorer()}>
              { showExplorerPanelState ? "Close Explorer" : "Open Explorer" }
            </MenuItem>
            <MenuItem onClick={() => handleCreateNote()}>
              Create note
            </MenuItem>
            <MenuItem onClick={() => handleDownloadNotes()}>
              Download notes as JSON file
            </MenuItem>
            <MenuItem onClick={() => handleShowHelp()}>
              Help
            </MenuItem>
            <MenuItem onClick={() => handleShowMax()}>
              Ask Max
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleShowSettings}>
              Settings
            </MenuItem>
            <MenuItem onClick={handleShowCLI}>
              CLI
            </MenuItem>
            <MenuItem onClick={signOut}>
              Logout
            </MenuItem>
          </Menu>
          <Button onClick={handleCreateNote}>+</Button>
          <Text flex={"1"}></Text>
          { ToolBarControls() }
        </Flex>
  
        <Flex id="content" overflow={"auto"}>
          { showExplorerPanelState === true ?
            (
              <Card id="explorer">
                <Explorer notes={filteredNotes} onViewNote={handleViewNote} onEditNote={handleEditNote} onDeleteNote={handleDeleteNote} onClose={handleExplorerClose}/>
              </Card>
            ) : null
          }
          { showExplorerPanelState === true && window.innerWidth > settings.minMultiPanelWidth ||
            showExplorerPanelState === false ?
            (
              <Card id="workarea" flex={"1"}>
                { createNotePanelState === true ?
                (
                  Note({note:currentNote, onClose:handleCancelCreateNote, onChange:setCurrentNote, onSave:handleSaveNote})
                    //CreateNote(setNewNoteText, handleSaveNote, handleCancelCreateNote)
                ) : null
                }
                { editNotePanelState === true ?
                (
                  Note({note:currentNote, onClose:handleCancelEditNote, onChange:setCurrentNote, onSave:handleUpdateNote, mode:'edit'})
                ) : null
                }
                { viewNotePanelState === true ?
                (
                    <Note key={currentNote.id + '.view'} note={currentNote} onClose={handleCloseViewNote} onChange={setCurrentNote} onSave={handleUpdateNote} mode='view'/>
                ) : null
                }
                { dashboardPanelState === true ?
                (
                    Dashboard()
                ) : null
                }
                { helpPanelState === true ?
                (
                    Help(handleShowExplorer)
                ) : null
                }
                { maxPanelState === true ?
                (
                    Max(context, maxCommand, setMaxCommand, maxCells, setMaxCells)
                ) : null
                }
                { cliPanelState === true ?
                (
                    CLI(context, cliCommand, setCliCommand, cliCells, setCliCells, hint, setHint)
                ) : null
                }
                { settingsPanelState === true ?
                    <Settings explorerSortBy={explorerSortBy} onSortChange={setExplorerSortBy} onClose={handleSettingsClose} />
                 : null
                }
              </Card>
          ) : null
        }
      </Flex>
      </Flex>
    )
  }

  return (
    <Authenticator hideSignUp={true}>
      { AppBody({ signOut, user }) }
    </Authenticator>
  )
}
export default App

