// Provide a non-editable full screen view of a note
import { Button, Flex, Heading, Text } from "@aws-amplify/ui-react";
export const NoteViewer = ({keybase, note}) => {
    return (
        <Flex direction={"column"} overflow={"auto"} height={"100%"}>
            <Heading>View Note</Heading>
            <Text
                key={keybase + '.text'}
                rows={20}
                flex={"1"}
                width={"100%"}
                height={"100%"}
                overflow={"auto"}>
                {note.text}
            </Text>
        </Flex>
    )
}
