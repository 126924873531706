import { Button, Text, Flex, Heading } from "@aws-amplify/ui-react";

  export const Help = (onClickHandler) => {
    return (
      <Flex flex={"1"} direction={"column"}>
        <Heading>Create Plan Do Help</Heading>
        <Text>Create notes for ideas, reflect on events in a journal, create goals and plans and tasks to achieve them.</Text>
        <Text>Plan how you will turn your ideas into reality by setting goals and workig out the steps you need to take to reach them.</Text>
        <Text>Create tasks and checklists to guide and track progress.</Text>
        <Flex direction={"row"}>
          <Button onClick={onClickHandler}>Show notes</Button>
        </Flex>
      </Flex>
    )
  }
