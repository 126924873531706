import { Button, Flex, Heading, TextAreaField } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
export const NoteEditor = ({keybase, note, onChange, mode='edit'}) => {
    const [noteText, setNoteText] = useState(note.text)
    useEffect(() => {
        onChange(noteText)
    }, [noteText])

    const onEditNoteChange = (e) => {
        const { value } = e.target
        setNoteText(value)
    }

    return (
        <Flex flex={"1"} direction={"column"}>
            <Heading>Text</Heading>
            <TextAreaField
                key={keybase + '.text'}
                rows={20}
                flex={"1"}
                width={"100%"}
                height={"100%"}
                resize={"both"}
                onChange={ onEditNoteChange }
                value={ noteText }>
            </TextAreaField>
        </Flex>
    )
}
