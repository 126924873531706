import { useState, useEffect } from "react"
import { View, Card, Badge, Flex, Heading, Text, useTheme } from "@aws-amplify/ui-react"
import { TagList } from "./TagList"
import { Context } from "./Context"

export const MetaData = ({keybase, note: {createdAt, updatedAt, type, status, tags, context, text}, onChange, mode='edit'}) => {

    //const [myId, setMyId] = useState(key)
    const [noteContext, setNoteContext] = useState(JSON.parse(context))
    const [tagList, setTagList] = useState(JSON.parse(tags))
    const [noteText, setNoteText] = useState(text)
    useEffect(() => {
        onChange({type:type, status:status, tags:JSON.stringify(tagList), context:JSON.stringify(noteContext)})
    }, [tagList])
    useEffect(() => {
        onChange({type:type, status:status, tags:JSON.stringify(tagList), context:JSON.stringify(noteContext)})
    }, [noteContext])

    // if we are being reinstantiated with different data, reset state
    //if (key !== myId) {
      //  setTagList(JSON.parse(tags))
        //setNoteContext(JSON.parse(context))
        //setMyId(key)
    //}

    const { tokens } = useTheme()

    const handleChangeTags = (tags) => {
        setTagList(tags)
    }
    const handleChangeContexts = (contexts) => {
        setNoteContext(contexts)
    }
    const handleSelectTag = (tag) => {
        console.log('select tag ' + tag)
    }
    const handleSelectContext = (context) => {
        console.log('select context ' + context)
    }

    return (
        <Flex direction={"column"}>
            <Heading>Meta-Data</Heading>
            <View
                backgroundColor={tokens.colors.background.secondary}
                padding={tokens.space.medium}
                style={{display : 'inline-block'}}
            >
                <Flex direction={"row"} style={{display : 'inline-block'}}>
                    <Card  key={keybase + '.base-meta-data'} style={{display: 'inline-block', margin: tokens.space.small}}>
                        <Flex direction={"row"}>
                            <Text>Type:</Text><Text>{type}</Text>
                        </Flex>
                        <Flex direction={"row"}>
                            <Text>Status:</Text><Text>{status}</Text>
                        </Flex>
                    </Card>
                    <Card  key={keybase + '.date-meta-data'} style={{display: 'inline-block', margin: tokens.space.small}}>
                        <Flex direction={"row"}>
                            <Text>Created:</Text><Text>{createdAt}</Text>
                        </Flex>
                        <Flex direction={"row"}>
                            <Text>Updated:</Text><Text>{updatedAt}</Text>
                        </Flex>
                    </Card>
                    <Context
                        key={keybase + '.context'}
                        keybase={keybase}
                        contexts={noteContext}
                        onChange={handleChangeContexts}
                        onSelect={handleSelectContext}
                        mode={mode}
                        />
                    <TagList
                        key={keybase + '.tags'}
                        tags={tagList} 
                        onChange={handleChangeTags}
                        onSelect={handleSelectTag}
                        mode={mode}
                    />
                </Flex>
            </View>
        </Flex>
    )
}
