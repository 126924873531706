// embers.js
// A collection of javascript helper functions

export const downloadObjectsAsJSON = (exportObj, exportName) => {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", exportName);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

export const yyyymmddhhmm = () => {
    function pad2(n) { return n < 10 ? '0' + n : n }
    const date = new Date();
    const dateTimeAsString = 
        date.getFullYear().toString() + 
        pad2(date.getMonth() + 1) + pad2( date.getDate()) + 
        pad2( date.getHours() ) + pad2( date.getMinutes() )
    return dateTimeAsString
}