import { useCallback, useEffect, useState, useRef } from 'react'
import { Text, Collection, Card, Flex, ScrollView, Heading, TextAreaField } from "@aws-amplify/ui-react"
import { Auth } from 'aws-amplify'
import { Configuration, OpenAIApi } from "openai";

export const Max = (context, maxCommand, setMaxCommand, maxCells, setMaxCells) => {

    const settings = {
        context: context
    }


    class OpenaiCommand {
        constructor(settings) {
            this.settings = settings
            this.commandName = 'openai'
            this.syntax = 'openai prompt\n  - prompt can be a command, question, or statement to be completed'
        }

        run(input, callback) {
            let result = ''
            result='\nRunning...'
            const configuration = new Configuration({
                apiKey: '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'
            })
            const openai = new OpenAIApi(configuration);
            const prompt = input
            openai.createCompletion({
                model: "text-davinci-002",
                prompt: prompt,
                temperature: 0,
                max_tokens: 4000,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
                }).then( (value) => {
                    callback(prompt + '\n' + value.data.choices[0].text)
                    }
                ).catch(
                    (error) => { callback(prompt + '\n' + 'ERROR: ' + error) }
                )
            return result === '' ? null : result
        }
    }

    const commands = [
        new OpenaiCommand(settings)
    ]

    const onCommandReturn = (result) => {
        setMaxCells(maxCells.concat([result]))
    }

    const onCommandChange = (e) => {
        const { value } = e.target
        setMaxCommand(value)
        if (value.endsWith("\n")) {
            let command = value.trim()
            for (let c of commands) {
                let result = null
                try {
                    result = c.run(command, onCommandReturn)
                    if (result !== null) {
                        setMaxCells(maxCells.concat([command + '\n' + result]))
                        break
                    }
                } catch(error) {
                    console.log(error)
                    setMaxCells(maxCells.concat([command + '\n' + 'ERROR: ' + error]))
                }
            }
            setMaxCommand('')
        }
    }

    const saveHistory = () => {

    }

    const closeMax = () => {

    }

    return (
        <Flex flex={"1"} direction={"column"}>
            <Heading>Max</Heading>
            <ScrollView flex={"1"}>
                { maxCells.length === 0 ? null :
                    <Collection
                    items={ maxCells }
                    type="list"
                    direction="row"
                    gap="5px"
                    wrap="wrap"
                >
                    {(item, index) => (
                        <Card
                        key={index}
                        borderRadius="medium"
                        width="100%"
                        variation="elevated"
                        display={"block"}
                        >
                        <Text>{item}</Text>
                        </Card>
                    )}
                </Collection>
              }
            </ScrollView>
            <TextAreaField
                id={"maxCommand"}
                rows={4}
                width={"100%"}
                onChange={ onCommandChange }
                value={ maxCommand }>
            </TextAreaField>
        </Flex>
    )
}
