import { useCallback, useEffect, useState, useRef } from 'react'
import { Text, Collection, Card, Flex, ScrollView, Heading, TextAreaField } from "@aws-amplify/ui-react"
import { Auth } from 'aws-amplify'
import { Configuration, OpenAIApi } from "openai";

export const CLI = (context, cliCommand, setCliCommand, cliCells, setCliCells, hint, setHint) => {

    const settings = {
        context: context
    }

    class HelpCommand {
        constructor(settings) {
            this.settings = settings
            this.commandName = 'help'
            this.syntax = 'help [command]'
        }

        hint(partialCommand=this.commandName) {
            let help = ''
            if (partialCommand.startsWith(this.commandName)) {
                help = this.syntax
            }
            return help === '' ? null : help
        }
        
        run(command, callback) {
            let result = ''
            if (command.startsWith(this.commandName)) {
                commands.forEach(element => {
                    result += element.hint() + '\n'
                });
            }
            return result === '' ? null : result
        }
    }

    class WhoAmICommand {
        constructor(settings) {
            this.settings = settings
            this.commandName = 'whoami'
            this.syntax = this.commandName
        }

        hint(partialCommand=this.commandName) {
            let help = ''
            if (partialCommand.startsWith(this.commandName)) {
                help = this.syntax
            }
            return help === '' ? null : help
        }
        
        run(command, callback) {
            let result = ''
            if (command.startsWith(this.commandName)) {
                result = 'Username: ' + settings.context.user.username + '\n' +
                'Email: ' + settings.context.user.attributes.email
            }
            return result === '' ? null : result
        }
    }

    class OpenaiCommand {
        constructor(settings) {
            this.settings = settings
            this.commandName = 'openai'
            this.syntax = 'openai prompt\n  - prompt can be a command, question, or statement to be completed'
        }

        hint(partialCommand=this.commandName) {
            let help = ''
            if (partialCommand.startsWith(this.commandName)) {
                help = this.syntax
            }
            return help === '' ? null : help
        }
        
        run(command, callback) {
            let result = ''
            if (command.startsWith(this.commandName)) {
                result='\nRunning...'
                const configuration = new Configuration({
                    apiKey: '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'
                })
                const openai = new OpenAIApi(configuration);
                const prompt = command.slice(this.commandName.length + 1)
                openai.createCompletion({
                    model: "text-davinci-002",
                    prompt: prompt,
                    temperature: 0,
                    max_tokens: 4000,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0,
                  }).then( (value) => {
                        callback(command + '\n' + value.data.choices[0].text)
                        }
                    ).catch(
                        (error) => { callback(command + '\n' + 'ERROR: ' + error) }
                    )
            }
        return result === '' ? null : result
        }
    }

    class PrintCommand {
        constructor(settings) {
            this.settings = settings
            this.commandName = 'print'
            this.syntax = this.commandName + ' message'
        }

        hint(partialCommand=this.commandName) {
            let help = ''
            if (partialCommand.startsWith(this.commandName)) {
                help = this.syntax
            }
            return help === '' ? null : help
        }
        
        run(command, callback) {
            let result = null
            if (command.startsWith('print ')) {
                result = command.slice(this.commandName.length + 1)
            }
            return result
        }
    }

    class AddUserCommand {
        constructor(settings) {
            this.settings = settings
            this.commandName = 'add'
            this.syntax = 'add user username email phone password'
        }

        hint(partialCommand=this.commandName) {
            let help = ''
            if (partialCommand.startsWith(this.commandName)) {
                help = this.syntax
            }
            return help === '' ? null : help
        }

        signUp(username, email, phone_number, password) {
            let result = 'add user: '
            try {
                const params = {
                    username,
                    password,
                    attributes: {
                        email,         // optional
                        phone_number   // optional - E.164 number convention
                    },
                    autoSignIn: {
                        enabled: true,
                    }
                }
                Auth.signUp(params).then(
                    (success) => {
                        result += 'user ' + params.username + ' created'
                        setCliCells(cliCells.concat([result]))
                    },
                    (error) => {
                        result += 'ERROR: ' + error
                        setCliCells(cliCells.concat([result]))
                    }
                ).catch( e => {
                    result += 'ERROR :: ' + e;
                })
            } catch (error) {
                result += 'ERROR : ' + error
            }
            return result
        }

        run(command, callback) {
            if (command.startsWith('add user ')) {
                let tokens = command.split(' ')
                const result = this.signUp(tokens[2], tokens[3], tokens[4], tokens[5])
                return result
            } else {
                return null
            }
        }
    }

    const commands = [
        new PrintCommand(settings),
        new AddUserCommand(settings),
        new WhoAmICommand(settings),
        new OpenaiCommand(settings)
    ]
    commands.push(new HelpCommand(settings))

    const onCommandReturn = (result) => {
        setCliCells(cliCells.concat([result]))
    }

    const onCommandChange = (e) => {
        const { value } = e.target
        setCliCommand(value)
        if (value.endsWith(' ')) {
            for (let c of commands) {
                let result = c.hint(value)
                if (result !== null) {
                    setHint(result)
                    break
                }
            }
        }
        if (value.endsWith("\n")) {
            setHint('')
            let command = value.trim()
            let commandFound = false
            for (let c of commands) {
                let result = null
                try {
                    result = c.run(command, onCommandReturn)
                    if (result !== null) {
                        commandFound = true
                        setCliCells(cliCells.concat([command + '\n' + result]))
                        break
                    }
                } catch(error) {
                    console.log(error)
                    commandFound = true
                    setCliCells(cliCells.concat([command + '\n' + 'ERROR: ' + error]))
                }
            }
            if (!commandFound) {
                setCliCells(cliCells.concat([command + '\n' + 'ERROR: No such command: ' + command]))
            }
            setCliCommand('')
        }
    }

    const saveHistory = () => {

    }

    const closeCli = () => {

    }

    const help = (params) => {
        
    }

    return (
        <Flex flex={"1"} direction={"column"}>
            <Heading>CLI</Heading>
            <ScrollView flex={"1"}>
                { cliCells.length === 0 ? null :
                    <Collection
                    items={ cliCells }
                    type="list"
                    direction="row"
                    gap="5px"
                    wrap="wrap"
                >
                    {(item, index) => (
                        <Card
                        key={index}
                        borderRadius="medium"
                        width="100%"
                        variation="elevated"
                        display={"block"}
                        >
                        <Text>{item}</Text>
                        </Card>
                    )}
                </Collection>
              }
            </ScrollView>
            <Text>{hint}</Text>
            <TextAreaField
                id={"cliCommand"}
                rows={4}
                width={"100%"}
                onChange={ onCommandChange }
                value={ cliCommand }>
            </TextAreaField>
        </Flex>
    )
}
