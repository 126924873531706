import { Heading, Badge, Text, Flex, ScrollView, Collection, Card, View, Button } from '@aws-amplify/ui-react';
import { MdOutlineClose, MdOutlineEditNote, MdOutlineDeleteForever } from "react-icons/md"

export const Explorer = ({ notes, onViewNote, onEditNote, onDeleteNote, onClose }) => {
    return (
        <Flex minWidth={"16rem"} direction={"column"} overflow={"auto"} height={"100%"}>
          <Card>
            <Flex direction={"row"}>
              <Heading>Explorer</Heading>
              <Text flex={1}/><Button size={"small"} onClick={() => { onClose() }}><MdOutlineClose/></Button>
            </Flex>
          </Card>
          <ScrollView
            minWidth={"16rem"}
            >
            <Collection
              items={ notes }
              type="list"
              direction="row"
              gap="20px"
              wrap="wrap"
            >
              {(item, index) => (
                  <Card
                    marginLeft={"auto"}
                    marginRight={"auto"}
                    key={index}
                    borderRadius="medium"
                    variation="elevated"
                    flex={"1"}
                  >
                    <View padding="xs">
                      <Flex direction={"column"}>
                        <ScrollView
                          height={"10rem"}
                          onClick={() => onViewNote(item)}>
                            {item.text}
                        </ScrollView>
                        <Flex direction={"row"}>
                          <Button onClick={() => onEditNote(item)}>Edit</Button>
                          <Button onClick={() => onDeleteNote(item.id)}>Delete</Button>
                        </Flex>
                      </Flex>
                    </View>
                  </Card>
                )}
              </Collection>
          </ScrollView>
        </Flex>    
    )
}