import { Menu, MenuItem, Button, Flex, Badge, Heading, Text } from "@aws-amplify/ui-react"
import { useState } from "react"
import { MetaData } from "./MetaData"
import { NoteViewer } from "./NoteViewer"
import { NoteEditor } from "./NoteEditor"
import { BsPlusSquare } from "react-icons/bs"
import { BsBoxArrowInRight } from "react-icons/bs"
import { MdOutlineClose } from "react-icons/md"
import { MdSave } from "react-icons/md"

export const Note = ({note, onClose, onChange, onSave, mode='edit'}) => {
    let noteContent = {}
    let key = ''
    if (note.id !== undefined) {
        key = note.id + '.' + mode
    } else {
        key = 'new_note.create'
    }

    const handleChangeNoteText = (text) => { // TODO: maybe use currentNote instead of normalisedNote
        let updatedNote = Object.assign({}, note, {text:text})
        onChange(updatedNote)
    }

    const handleChangeNoteMetaData = (metaData) => {
        let updatedNote = Object.assign({}, note, metaData)
        onChange(updatedNote)
    }

    const handleSaveNote = () => {
        onSave()
    }

    return (
        <Flex direction={"column"} flex={"1"}>
            <Heading>{ mode === 'edit' ? 'Edit' : 'View' } Note</Heading>
            <Flex direction={"row"}>
                <Menu size={"small"}>
                    { mode === 'edit' ?
                    (<MenuItem onClick={() => handleSaveNote()}>Save</MenuItem>) :
                    null
                    }
                    <MenuItem onClick={onClose}>Close</MenuItem>
                </Menu>
                { mode === 'edit' ?
                    (<Button size={"small"} onClick={() => { handleSaveNote() }}><MdSave/> Save</Button>)
                    : null
                }
                <Text flex={1}/>
                <Button size={"small"} onClick={() => { onClose() }}><MdOutlineClose/></Button>
            </Flex>
            <MetaData key={key + '.metadata'} keybase={key} note={note} onChange={handleChangeNoteMetaData} mode={mode}/>
            { mode === 'view' ?
                (<NoteViewer key={key + '.view'} keybase={key} note={note}/>)
                : (<NoteEditor key={key + '.edit'} keybase={key} note={note} onChange={handleChangeNoteText}/>)
            }
        </Flex>
    )
}
