import { Flex } from "@aws-amplify/ui-react"
import { useState } from 'react'
export const DateControl = () => {
    const [dateToday, setDateToday] = useState('')

    const refresh = () => {
      const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
      let today = new Date();
      let day = days[today.getDay()]
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0
      let yy = today.getFullYear().toString().substring(2);

      today = day + ' ' + mm + '/' + dd + '/' + yy;
      setDateToday(today);
    }

    if (dateToday === '') {
      refresh()
    }

    return (
      <Flex>{dateToday}</Flex>
    )
  }