import { Flex } from "@aws-amplify/ui-react"
export const Clock = () => {
    const delay = (time) => {
        return new Promise(resolve => setTimeout(resolve, time))
      }
    
    let time = new Date().toLocaleTimeString().substring(0,5)
    return (
        <Flex>{ time }</Flex>
    )
}