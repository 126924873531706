import { View, Card, Heading, Button, Flex, Badge, Text, TextField, useTheme } from "@aws-amplify/ui-react"
import { useState, useEffect } from 'react'
import { MdOutlineClose } from "react-icons/md"

export const Context = ({keybase, contexts, onChange, onSelect, mode='edit'}) => {
    const [newContext, setNewContext] = useState('')
    const [editedContexts, setEditedContexts] = useState(contexts == undefined ? [] : contexts)
    useEffect(() => {
        onChange(editedContexts)
    }, [editedContexts])

    const { tokens } = useTheme()

    const handleChange = (e) => {
        const { value } = e.target
        setNewContext(value)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setEditedContexts(editedContexts.concat(newContext))
            setNewContext('')
        }
    }

    const handleDeleteContext = (context) => {
        setEditedContexts(editedContexts.filter((c) => c !== context))
    }


    return (
        <Card  key={keybase + '.context'} style={{display: 'inline-block', margin: tokens.space.small}}>
            <Flex direction={"row"} style={{display : 'inline-block'}}>
                <Heading>Contexts</Heading>
                {
                    editedContexts.map((context) => 
                        <Badge key={keybase + context} variation='info' margin={"2px"}>
                            { (mode === 'edit') ?
                                (<Text paddingRight={"4px"} onClick={() => handleDeleteContext(context)}><MdOutlineClose/></Text>)
                                : null
                            }
                            <Text fontWeight={"bold"} onClick={() => onSelect(context)}>{context}</Text>
                        </Badge>
                    )
                }
                {
                    (
                        (mode === 'edit') ?
                            (<TextField size="small" placeholder="Add context" value={newContext}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            />)
                            : null
                    )
                }
            </Flex>
        </Card>
    )
}