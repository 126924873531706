import {View, Card, Heading, Button, Flex, Badge, Text, TextField, useTheme } from "@aws-amplify/ui-react"
import { useState, useEffect } from 'react'
import { MdOutlineClose } from "react-icons/md"

export const TagList = ({keybase, tags, onChange, onSelect, mode='edit'}) => {
    const [newTag, setNewTag] = useState('')
    const [editedTags, setEditedTags] = useState(tags == undefined ? [] : tags)
    useEffect(() => {
        onChange(editedTags)
    }, [editedTags])

    const { tokens } = useTheme()

    const handleChange = (e) => {
        const { value } = e.target
        setNewTag(value)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setEditedTags(editedTags.concat(newTag))
            setNewTag('')
        }
    }

    const handleDeleteTag = (tag) => {
        setEditedTags(editedTags.filter((t) => t !== tag))
    }


    return (
        <Card key={keybase + '.tag'} style={{display : 'inline-block', margin: tokens.space.small}}>
            <Flex direction={"row"} style={{display : 'inline-block'}}>
                <Heading>Tags</Heading>
                {
                    editedTags.map((tag) => 
                        <Badge key={keybase + tag} variation='info' margin={"2px"}>
                            { (mode === 'edit') ?
                                (<Text paddingRight={"4px"} onClick={() => handleDeleteTag(tag)}><MdOutlineClose/></Text>):
                                null
                            }
                            <Text fontWeight={"bold"} onClick={() => onSelect(tag)}>{tag}</Text>
                        </Badge>
                    )
                }
                {
                    (mode === 'edit') ?
                        (<TextField size="small" placeholder="Add tag" value={newTag}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}/>) :
                        null
                }
            </Flex>
        </Card>
    )
}