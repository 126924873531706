import { Text, Flex, Heading } from "@aws-amplify/ui-react";
export const Dashboard = () => {
    return (
      <Flex flex={"1"}>
        <Heading>Dashboard</Heading>
        <Text>Coming soon...</Text>
      </Flex>
    )
  }

